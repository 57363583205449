export const CLINICIAN_HOME = "/clinician/home";
export const CLINICIAN_ECGLIST = "/clinician/ecglist";
export const CLINCIAN_REVIEW = "/clinician/ecglist/review/:id/:completed";
export const RESOURCES = "/resources";
export const ADMIN_REPORT = "/admin/clinician/report";

export const ADMIN_HOME = "/admin/clinician/home";
export const ADMIN_ECGLIST = "/admin/clinician/ecglist";
export const ADMIN_CLINCIAN_REVIEW =
  "/admin/clinician/ecglist/review/:id/:completed";
export const ADMIN_RESOURCES = "/admin/resources";
