import React from "react";
import { Grid } from "@material-ui/core";

import EkgRecording from "../EkgRecording/EkgRecording";

let key = 1;
function EkgCharts(props) {
  const { recording, is6l, duration } = props;

  return (
    <Grid container>
      <EkgRecording
        key={key++}
        height={700}
        width={1500}
        recording={recording}
        rowSeconds={15}
        calpulse={true}
        inverted={recording && recording.inverted}
        is6l={is6l}
        duration={duration}
      />
    </Grid>
  );
}

export default EkgCharts;
