/* eslint-disable */
import { combineReducers } from "redux";
import UserReducers from "./User";
import ekgListTableReducers from "./EkgListTable";
import EkgListReducers from "./EkgList";
import EkgSearchReducers from "./EkgSearch";
import EkgReviewReducers from "./EkgReview";
import EkgReportReducers from "./EkgReport";
import EkgResourceReducers from "./EkgResources";

const rootReducer = combineReducers({
  user: UserReducers,
  ekgListTable: ekgListTableReducers,
  ekgList: EkgListReducers,
  ekgSearch: EkgSearchReducers,
  ekgReview: EkgReviewReducers,
  ekgReport: EkgReportReducers,
  ekgResource: EkgResourceReducers,
});

export default (state, action) => rootReducer(state, action);
